<template>
  <div class="signa-list-in-list">
    <BaseList
      :list-query="signa"
      route="signum"
      :filter="signum => !onlyPrimary"
      class="signas-list"
      locale-section="pages.signa"
    >
      <template
        v-slot:element="{
          element: { signum2, id },
          isDraft: isDraft
        }"
      >
        <router-link
          :class="{
            'is-draft': isDraft(id)
          }"
          :to="{
            name: 'signum',
            params: { id: id }
          }"
          >{{ signum1 }} {{ signum2 }}</router-link
        >
      </template>
    </BaseList>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "SignaList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  props: {
    signum1: { type: String, default: "" },
    onlyPrimary: { type: Boolean, default: true }
  },
  data() {
    return {
      signa: {
        query: gql`
          query signa($signum1: String!) {
            list: signa(signum1: $signum1) {
              id
              name: signum2
              signum1 {
                signum1
              }
              signum2
            }
          }
        `,
        variables: {
          signum1: this.signum1
        }
      }
    };
  }
};
</script>
