var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signa-list-in-list"},[_c('BaseList',{staticClass:"signas-list",attrs:{"list-query":_vm.signa,"route":"signum","filter":signum => !_vm.onlyPrimary,"locale-section":"pages.signa"},scopedSlots:_vm._u([{key:"element",fn:function({
        element: { signum2, id },
        isDraft: isDraft
      }){return [_c('router-link',{class:{
          'is-draft': isDraft(id)
        },attrs:{"to":{
          name: 'signum',
          params: { id: id }
        }}},[_vm._v(_vm._s(_vm.signum1)+" "+_vm._s(signum2))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }